<template>
  <router-link
    :to="{ name: 'courseDetail', query: { id: detail.id } }"
    class="course-card"
  >
    <img class="course-card-cover" :src="detail.courseimage" />
    <div class="course-card-detail">
      <div class="course-card-title">{{ detail.title }}</div>
      <div class="course-card-intro">{{ detail.description }}</div>
      <!-- <div class="course-card-intro" v-html="detail.content"></div> -->
    </div>
  </router-link>
</template>

<script>
export default {
  name: "course-card",
  props: {
    detail: Object,
  },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  // 课程
  .course-card {
    height: 266px;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    margin: 18px 0;
    display: flex;

    // 封面
    &-cover {
      width: 340px;
      height: 246px;
      object-fit: cover;
      margin: 10px;
      display: block;
    }

    // 详情
    &-detail {
      margin: 10px;
      padding: 30px 0;
    }

    // 标题
    &-title {
      color: #ed207b;
      font-size: 24px;
      @include ellipsis;
    }

    // 简介
    &-intro {
      font-size: 14px;
      line-height: 28px;
      color: #353535;
      margin-top: 10px;
      height: 140px;
      overflow: hidden;
    }
  }
}
</style>

<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  // 课程
  .course-card {
    // height: rpx(210);
    width: stretch;
    margin: 0 rpx(30);
    padding: rpx(30) 0;
    display: flex;
    align-items: center;
    border-bottom: rpx(2) solid #dcdcdc;

    // 封面
    &-cover {
      width: rpx(204);
      height: rpx(180);
      display: block;
      object-fit: cover;
    }

    // 详情
    &-detail {
      margin: 0 rpx(30);
      flex: 1;
      width: 0;
      // padding: rpx(30) 0;
    }

    // 标题
    &-title {
      color: #ed207b;
      font-size: rpx(24);
      @include ellipsis;
    }

    // 简介
    &-intro {
      font-size: rpx(24);
      line-height: rpx(32);
      color: #353535;
      margin-top: rpx(10);
      // height: rpx(128);
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
    }
  }
}
</style>
