<template>
  <container ref="container" @error="init">
    <!-- 课程 -->
    <div class="course">
      <!-- 课程-列表 -->
      <div class="course-list">
        <!-- 课程-元素 -->
        <course-card
          :detail="item"
          v-for="item in course.list"
          :key="item.id"
        />
      </div>

      <!-- 分页 -->
      <!-- <pagination :total="10" :current="5" /> -->
    </div>
  </container>
</template>

<script>
// import pagination from "@/components/pagination.vue";
import courseCard from "@/components/course-card.vue";

export default {
  name: "universityCourse",
  components: {
    // pagination,
    courseCard,
  },
  data() {
    return {
      course: {
        list: [],
      },
    };
  },
  mounted() {
    // 初始化
    this.init();
  },
  methods: {
    /**
     * 初始化数据
     */
    async init() {
      try {
        // 设置状态
        this.$refs.container.status("init", {
          height: "100vh",
        });

        // 获取数据
        const result = await this.$axios("/api/mainstation/yuesaoUniversity");

        // 设置数据
        this.course.list = result.data;

        // 设置状态
        this.$refs.container.status("default");
      } catch (e) {
        // 设置状态
        this.$refs.container.status("error", {
          test: e.message,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  // 新闻
  .course {
    width: 1200px;
    margin: auto;

    // 新闻-列表
    &-list {
      padding: 40px 0;
    }
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  // 新闻
  .course {
    width: 100%;
    margin: auto;

    // 新闻-列表
    &-list {
      padding: rpx(40) 0;
    }
  }
}
</style>
